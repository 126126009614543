<template>
  <div class="top">
    <h2 style="flex:1;"></h2>
    <h2 style="color:white;flex:1;letter-spacing: 10px;">燃气监管一张图</h2>
    <h5 style="color:white;flex:1;"> {{ timeDate|formatDate }}</h5>
  </div>
</template>

<script>
var formatNum = function(num){
  return num < 10 ? "0" + num : num
}
export default {
  name: "top",
  data(){
    return{
      timeDate:new Date(),
    }
  }, filters: {
    // 实时-时间-数据转换
    formatDate(value) {
      var year = value.getFullYear()
      var month = formatNum(value.getMonth() + 1)
      var day = formatNum(value.getDate())
      var hour = formatNum(value.getHours())
      var min = formatNum(value.getMinutes())
      var sec = formatNum(value.getSeconds())
      return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec
    }
  },
  mounted() {
    // 实时-时间-计时
    var that = this
    var timer = setInterval(function(){
      that.timeDate = new Date()
    },1000)
  }
}
</script>

<style scoped>
.top{
  width:100%;
  margin:auto;
  height:100px;
  background-image: url("../assets/top.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
  font-size:20px;
  position: absolute;
  top:0;
  left: 0;
}
</style>
