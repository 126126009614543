<template>
  <div class="container_warpper">
    <div class="taitou">
<!--      <div class="btn1">-->
<!--        <div class="btn_item">全县概况</div>-->
<!--        <div class="btn_item">供气保障</div>-->
<!--      </div>-->
      <div class="taitou_title">藁城智慧燃气及安全预警系统</div>
<!--      <div  class="btn2">-->
<!--        <div class="btn_item2">运行安全</div>-->
<!--        <div class="btn_item2">服务监管</div>-->
<!--      </div>-->
    </div>

    <div class="container">
      <div class="left">
          <div class="left01">
             <div class="rqqye">
               <div class="title">
                 <img src="../assets/qiye.png"/>
                 <div>燃气企业</div>
               </div>
               <div class="sub_title">
                 <div style="display: flex;align-items: center;">
                   <img src="../assets/qiyezongshu.png"/>
                   <div style="margin-left:10px;font-size:15px;">企业总数</div>
                 </div>
                 <h3 class="number">0</h3>
               </div>
               <div id="qyzs"></div>
             </div>
             <div class="cyry">
               <div class="title">
                 <img src="../assets/congyerenyuan@2x.png"/>
                 <div>从业人员</div>
               </div>
               <div class="sub_title">
                 <div style="display: flex;align-items: center;">
                   <img src="../assets/renyuanzongshu@2x.png"/>
                   <div style="margin-left:10px;font-size:15px;">人员总数</div>
                 </div>
                 <h3 class="number">0</h3>
               </div>
               <div id="cyry">
                 <table>
                        <tbody>
                           <tr>
                              <th><div>从业人员类别</div><div>人数</div></th>
                              <th><div>从业人员类别</div><div>人数</div></th>
                           </tr>
                           <tr>
                             <td><div>汽车加气站操作工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                             <td><div>瓶装燃气送气工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                           </tr>
                           <tr >
                             <td><div>液化天然气储运工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                             <td><div>安全生产管理人员</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                           </tr>
                           <tr>
                             <td><div>燃气用户安装工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                             <td><div>燃气输配场站工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                           </tr>
                           <tr>
                             <td><div>燃气管网运行工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                             <td><div>压缩天然气场站工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                           </tr>
                           <tr>
                             <td><div>企业主要负责人</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                             <td><div>液化石油气库站工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{0}}</div></td>
                           </tr>
<!--                           <tr>-->
<!--                             <td><div>汽车加气站操作工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{}}</div></td>-->
<!--                             <td><div>汽车加气站操作工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{}}</div></td>-->
<!--                           </tr>-->
<!--                           <tr>-->
<!--                             <td><div>汽车加气站操作工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{}}</div></td>-->
<!--                             <td><div>汽车加气站操作工</div><div style="color:#E0F9FF;font-family:D-DIN-DIN-Bold;">{{}}</div></td>-->
<!--                           </tr>-->
                        </tbody>
                 </table>
               </div>
             </div>
          </div>
          <div class="left02">
             <div class="rqcz">
               <div class="title">
                 <img src="../assets/ranqichangzhan@2x.png"/>
                 <div>燃气场站</div>
               </div>
               <div class="sub_title">
                 <div style="display: flex;align-items: center;">
                   <img src="../assets/changzhanzongshu@2x.png"/>
                   <div style="margin-left:10px;font-size:15px;">场站总数</div>
                 </div>
                 <h3 class="number">0</h3>
               </div>
               <div id="rqcz"></div>
             </div>
             <div class="czjf">
               <div class="sub_title">
                 <div style="display: flex;align-items: center;">
                   <img src="../assets/changzhanjifang@2x.png"/>
                   <div style="margin-left:10px;font-size:25px;font-family: 优设标题黑;">场站技防设备</div>
                 </div>
                 <h3 class="number">0</h3>
               </div>
               <div id="czjf">
                 <div class="czjf_top_warpper">
                 <div class="czjf_top">
                    <div v-for="item in jf" class="czjf_top_item">
                      <div>{{ item.name }}</div>
                      <div style="position: absolute;margin-left:35px;margin-top: 10px;font-size:25px;font-family: D-DIN-DIN-Bold;color:#D9F8FF;">
                        {{ item.number }}</div>
                      <img src="../assets/dizuo.png">
                    </div>
                 </div>
                 </div>
               </div>
             </div>
          </div>
      </div>
      <baidu-map class="bm-view" :center="center" :zoom="zoom" @ready="handler"  :scroll-wheel-zoom="true" >
          <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-map-type>
          <bm-info-window :position="{lng: infoWindow.lng, lat: infoWindow.lat}"  :show="infoWindow.show" @close="infoWindowClose()">
            <div class="info_title">
              <div>企业详情</div>
              <div style="line-height:10px;" @click="infoWindowClose()"><img src="../assets/cha.png"></div>
            </div>
            <div class="sucess_window">
              <div class="title2">
                <span>企业名称：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>统一社会信用代码：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>燃气经营许可证编号：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>发证日期：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>有效期限：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>经营区域：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>发证机关：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>气源种类：</span> <span>{{ infoWindow.userName }}</span>
              </div>
              <div class="title2">
                <span>经营类别：</span> <span>{{ infoWindow.userSPhoneNumber }}</span>
              </div>
              <div class="title2">
                <span>法人代表：</span> <span>{{ infoWindow.address}}</span>
              </div>
              <div class="title2">
                <span>联系电话</span> <span>{{ infoWindow.gasMeterNumber }}</span>
              </div>
              <div class="title2">
                <span>详细地址：</span><el-button type="text" size="mini" icon="el-icon-switch-button" @click="guan(infoWindow)"></el-button>
              </div>
            </div>
          </bm-info-window>
          <bm-info-window :position="{lng: item.lng, lat: item.lat}"  :show="item.show" @close="infoWindowClose()" v-for="item in points" class="infowindow">
            <div class="err_window">
              <div class="title2">
                <span>故障类型</span> <span>{{ item.userName }}</span>
              </div>
              <div class="title2">
                <span>问题描述</span> <span>{{ item.userSPhoneNumber }}</span>
              </div>
              <div class="title2">
                <span>故障地址</span> <span>{{ item.address}}</span>
              </div>
              <div class="title2">
                <span>联系人</span> <span>{{ item.gasMeterNumber }}</span>
              </div>
              <div class="title2">
                <span>负责人</span> <span>{{ item.deviceStatus }}</span>
              </div>
              <div class="title2">
                <span>报警时间</span><el-button type="primary" icon="el-icon-switch-button" @click="guan(item)"></el-button>
              </div>
            </div>
          </bm-info-window>
          <bm-marker  v-if="item.deviceStatus==0" :position="{lng: item.lng, lat: item.lat}" :icon="{url:mapIcon2, size: {width: 52, height: 52}}"  @click="infoWindowOpen(item)" :dragging="false" v-for="item in points">
          </bm-marker>
          <bm-marker v-if="item.deviceStatus==1" :position="{lng: item.lng, lat: item.lat}" :icon="{url:mapIcon3, size: {width: 52, height: 52}}"  @click="infoWindowOpen(item)"  :dragging="false"  v-for="item in points">
          </bm-marker>
          <bm-marker v-if="item.deviceStatus==2" :position="{lng: item.lng, lat: item.lat}" :icon="{url:mapIcon1, size: {width: 52, height: 52}}" @click="infoWindowOpen(item)"  :dragging="false" v-for="item in points"></bm-marker>
     </baidu-map>
      <div class="right">
          <div class="right01">
             <div class="spss">
               <div class="title">
                 <img src="../assets/shubeisheshi@2x.png"/>
                 <div>输配设施</div>
               </div>
               <div class="sub_title2">
                 <div style="display: flex;align-items: center;">
                   <img src="../assets/erji.png" style="width:50px;"/>
                   <div style="font-size:15px;">输配管网</div>
                 </div>
                 <h3 class="number">0</h3>
               </div>
               <div class="spss_wrapper">
                 <div style="display: flex;justify-content: space-between;">
                  <div id="spss"></div>
                  <div class="spss_right">
                      <div>
                         <div>管网总里程数</div>
                         <div class="number">0</div>
                      </div>
                     <div>
                        <div>老旧官网里程数</div>
                        <div class="number">0</div>
                     </div>
                    <div>
                      <div>计划改造里程数</div>
                      <div class="number">0</div>
                    </div>
                  </div>
                 </div>
                 <div class="middle">
                   <div>
                     <div class="number">0</div>
                     <div>高压管网</div>
                   </div>
                   <div>
                     <div class="number">0</div>
                     <div>次高压管网</div>
                   </div>
                   <div>
                     <div class="number">0</div>
                     <div>中压管网</div>
                   </div>
                   <div>
                     <div class="number">0</div>
                     <div>低压管网</div>
                   </div>
                 </div>
                 <div class="middle">
                   <div>
                     <div class="number">0</div>
                     <div>调压装置（个）</div>
                   </div>
                   <div>
                     <div class="number">0</div>
                     <div>阀门井（个）</div>
                   </div>
                 </div>
               </div>

             </div>
             <div class="gwjf">
               <div class="title">
                 <img src="../assets/qiye.png"/>
                 <div>官网技防设备</div>
               </div>
               <div class="gwjf2">
                 <div class="czjf_top">
                   <div v-for="item in jf2" class="czjf_top_item">
                     <div>{{item.name}}</div>
                     <div style="position: absolute;margin-left:35px;margin-top: 10px;font-size:25px;font-family: D-DIN-DIN-Bold;color:#D9F8FF;">
                       {{ item.number }}</div>
                     <img src="../assets/dizuo.png">
                   </div>
                 </div>
               </div>
             </div>
          </div>
          <div class="right02">
            <div class="rqyh">
              <div class="title">
                <img src="../assets/ramqiyonghu@2x.png"/>
                <div>燃气用户</div>
              </div>
              <div class="sub_title2">
                <div style="display: flex;align-items: center;">
                  <img src="../assets/erji.png" style="width:50px;"/>
                  <div style="margin-left:10px;font-size: 20px;font-family: 优设标题黑;">管道燃气用户</div>
                </div>
                <h3 class="number">0</h3>
              </div>
              <div class="rq_container">
              <div id="rqyh"></div>
              <div class="gdjfsb">
                 <div class="gdjf_text">
                    <div style="display: flex;align-items: center;width:160px;text-align: left;">
                      <img src="../assets/guandaoqi.png">
                      <div>管道气户内技防设备(户)</div>
                    </div>
                    <div class="number">0</div>
                 </div>
                <div id="ndjc"></div>
              </div>
                </div>
            </div>

            <div class="pzq">
              <div class="title">
                <img src="../assets/erji.png" style="width:50px;"/>
                <div>瓶装气用户</div>
              </div>
              <div class="pz_container">
                <div id="pzqtj"></div>
                  <div class="gdjfsb">
                    <div class="gdjf_text">
                      <div style="display: flex;align-items: center;width:160px;text-align: left;">
                        <img src="../assets/guandaoqi.png">
                        <div>管道气户内技防设备(户)</div>
                      </div>
                      <div class="number">0</div>
                    </div>
                       <div id="pznd"></div>
                  </div>
              </div>
          </div>
          </div>
      </div>
    </div>
<!--    <div class="bottm_menu">-->
<!--      <div class="bott2">-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <div style="width:10px;height:10px;background-color:#FEAD1B;"></div>-->
<!--        <span style="color:#FEAD1B;margin-left: 5px;">高压管网</span>-->
<!--      </div>-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <div style="width:10px;height:10px;background-color:#C78EFF;"></div>-->
<!--        <span style="color:#C78EFF;margin-left: 5px;">次高压管网</span>-->
<!--      </div>-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <div style="width:10px;height:10px;background-color:#46E9FF;"></div>-->
<!--        <span style="color:#46E9FF;margin-left: 5px;">中压高压管网</span>-->
<!--      </div>-->
<!--      <div style="display: flex;align-items: center;">-->
<!--        <div style="width:10px;height:10px;background-color:#0DFF8B;"></div>-->
<!--        <span style="color:#0DFF8B;margin-left: 5px;">低压管网</span>-->
<!--      </div>-->
<!--       </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import mapIconPath1 from "../assets/1.png"
import mapIconPath2 from "../assets/2.png"
import mapIconPath3 from "../assets/3.png"
import * as echarts from 'echarts';

export default {
  name: 'HomeView',
  data(){
    return{
      num1:[
        {number:"0"},
        {number:"0"},
        {number:"0"},
        {number:"0"},
        {number:"0"},
        {number:"0"},
        {number:"0"},
      ],
      // center: {lng:114.641921, lat:37.912368},
      center: {lng:116.12, lat:38.20},
      zoom:12,
      shebei:{},
      error:[],
      points:[],
      show:false,
      mapIcon1:mapIconPath1,
      mapIcon2:mapIconPath2,
      mapIcon3:mapIconPath3,
      infoWindow: {show:false,lng:"",lat:"",userName:"",userSPhoneNumber:"",gasMeterNumber:"",address:""},
      info:{},
      errordata:{imei:"864879061770154"},
      jf:[
        {name:"阴极保护装置",number:"0"},
        {name:"超限报警",number:"0"},
        {name:"消防系统",number:"0"},
        {name:"防雷接地装置",number:"0"},
        {name:"激光云台",number:"0"},
        {name:"视频安防系统",number:"0"},
      ],
      jf2:[
        {name:"流量监测装置",number:"0"},
        {name:"管网仿真系统",number:"0"},
        {name:"阴极保护装置",number:"0"},
        {name:"防雷接地装置",number:"0"},
        {name:"激光云台",number:"0"},
        {name:"视频安防系统",number:"0"},
      ]

    }
  },methods:{
    //企业总数
    qyzs(){
      var myChart = echarts.init(document.getElementById('qyzs'));
      var option = {
        title:{
          text:'企业总数',
          subtext:'0',
          left:'center',
          top:'25%',
          padding:[0,0],
          textStyle:{
            color:'#9CEDFF',
            fontSize:15,
            align:'center'
          },
          subtextStyle:{
            color:'#D9F8FF',
            fontSize:35,
            align:'center',
            fontWeight:"bold"
          },
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          bottom:'15%',
        },
        legend:[{
          data:['燃气企业','加气企业'],
          itemHeight: 9,
          itemWidth:9,
          bottom: '30%',
          left: 'center',
          itemGap: 100,
          textStyle: {
            fontSize: 15,//图例文字字体大小
            color: '#5BB2F1'//图例文字颜色
          },
        },{
          data:['瓶装企业','其他企业'],
          itemHeight: 9,
          itemWidth:9,
          bottom: '15%',
          left: 'center',
          itemGap: 100,
          textStyle: {
            fontSize: 15,//图例文字字体大小
            color: '#5BB2F1'//图例文字颜色
          },
        }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            center: ['50%', '30%'],
            radius: ['50%', '40%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '燃气企业' },
              { value: 735, name: '加气企业' },
              { value: 580, name: '瓶装企业' },
              { value: 484, name: '其他企业' },
            ]
          }
        ]
      };
      myChart.setOption(option)
    },
    rqcz(){
      var myChart = echarts.init(document.getElementById('rqcz'));
      var option = {
        title:{
          text:'场站总数',
          subtext:'0',
          left:'center',
          top:'30%',
          padding:[0,0],
          textStyle:{
            color:'#9CEDFF',
            fontSize:15,
            align:'center'
          },
          subtextStyle:{
            color:'#D9F8FF',
            fontSize:35,
            align:'center',
            fontWeight:"bold"
          },
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          bottom:'15%',
        },
        legend:[{
          data:['储配站','门站','气化站','混气站','加气站','罐装站','供应站','调压站','其他','加氢站','混合型场站'],
          itemHeight: 8,
          itemWidth:8,
          bottom: '5%',
          left: 'center',
          itemGap: 25,
          align:'left',
          textStyle: {
            fontSize: 13,//图例文字字体大小
            color: '#5BB2F1'//图例文字颜色
          },
        }
        ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            center: ['50%', '35%'],
            radius: ['60%', '50%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '储配站' },
              { value: 735, name: '门站' },
              { value: 580, name: '气化站' },
              { value: 484, name: '混气站' },
              { value: 1048, name: '加气站' },
              { value: 735, name: '罐装站' },
              { value: 580, name: '供应站' },
              { value: 484, name: '调压站' },
              { value: 1048, name: '其他' },
              { value: 735, name: '加氢站' },
              { value: 580, name: '混合型场站' },
            ]
          }
        ]
      };
      myChart.setOption(option)
    },
    spss(){
      var myChart = echarts.init(document.getElementById('spss'));
      var option = {
        title:{
          text:'老旧占比',
          subtext:'0%',
          left:'center',
          top:'35%',
          padding:[0,0],
          textStyle:{
            color:'#9CEDFF',
            fontSize:15,
            align:'center',
          },
          subtextStyle:{
            color:'#D9F8FF',
            fontSize:20,
            align:'center',
            fontWeight:"bold"
          },
        },
        tooltip: {
          trigger: 'item'
        },
        grid: {
          bottom:'15%',
        },
        // legend:[{
        //   data:['储配站','门站','气化站','混气站','加气站','罐装站','供应站','调压站','其他','加氢站','混合型场站'],
        //   itemHeight: 9,
        //   itemWidth:8,
        //   bottom: '0%',
        //   left: 'center',
        //   itemGap: 20,
        //   align:'left',
        //   textStyle: {
        //     fontSize: 13,//图例文字字体大小
        //     color: '#5BB2F1'//图例文字颜色
        //   },
        // }
        // ],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            center: ['50%', '50%'],
            radius: ['80%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '新' },
              { value: 2000, name: '旧' },
            ]
          },
        ]
      };
      myChart.setOption(option)
    },
    rqyh(){
      var myChart = echarts.init(document.getElementById('rqyh'));
      var option = {
        title:{
          text:'0',
          subtext:'普通气用户',
          left:'center',
          top:'35%',
          padding:[0,0],
          textStyle:{
            color:'#9CEDFF',
            fontSize:15,
            align:'center'
          },
          subtextStyle:{
            color:'#D9F8FF',
            fontSize:15,
            align:'center',
            fontWeight:"bold"
          },
        },
        tooltip: {
          show: false,
        },
        legend:[{
          data:['单位用户','居民用户'],
          itemHeight: 9,
          itemWidth:8,
          top:'65%',
          // bottom: '0%',
          left: 'center',
          itemGap: 150,
          align:'left',
          textStyle: {
            fontSize: 13,//图例文字字体大小
            color: '#5BB2F1'//图例文字颜色
          },
        }
        ],
        color: ['#01dadc', '#23cea7', '#5096e0'],
        series: [
          {
            name: '单位用户',
            type: 'pie',
            startAngle: 0,
            radius: ['60%', '67%'],
            center: ['50%', '50%'],
            legendHoverLink: false,
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 300,
              itemStyle: {
                normal: {
                  color: "rgba(35,206,167,0)"
                }
              }
            },
              {
                value: 210,
                itemStyle: {
                  normal: {
                    color: "rgba(35,206,167,1)"
                  }
                }
              },
              {
                value: 90,
                itemStyle: {
                  normal: {
                    color: "rgba(35,206,167,0.1)"
                  }
                }
              },
            ]
          },
          {
            name: '居民用户',
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ["80%", "87%"],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 300,
              itemStyle: {
                normal: {
                  color: "rgba(1,218,220,0)"
                }
              }
            },
              {
                value: 240,
                itemStyle: {
                  normal: {
                    color: "rgba(1,218,220,1)"
                  }
                }
              },
              {
                value: 60,
                itemStyle: {
                  normal: {
                    color: "rgba(1,218,220,0.1)"
                  }
                }
              },
            ]
          }
        ]
      };
      myChart.setOption(option)
    },
    ndjc(){
      var myChart = echarts.init(document.getElementById('ndjc'));
      var option = {
        title: {

        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          top:'10%',
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          show:false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          splitLine: {show: false},   //横向的线
          axisTick: {show: false},    //y轴的端点
          axisLine: {show: false},
          data: ['浓度检测报警装置', '联动切断装置',],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#5BB2F1',
              fontSize:'13px'//y轴字体颜色
            },
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: '#00b5eb',
              }
            },
            data: [0,0]
          },
        ]
      };
      myChart.setOption(option)
    },
    pzqtj(){
      var myChart = echarts.init(document.getElementById('pzqtj'));
      var option = {
        title:{
          text:'0',
          subtext:'普通气用户',
          left:'center',
          top:'35%',
          padding:[0,0],
          textStyle:{
            color:'#9CEDFF',
            fontSize:15,
            align:'center'
          },
          subtextStyle:{
            color:'#D9F8FF',
            fontSize:15,
            align:'center',
            fontWeight:"bold"
          },
        },
        tooltip: {
          show: false,
        },
        legend:[{
          data:['单位用户','居民用户'],
          itemHeight: 9,
          itemWidth:8,
          top:'65%',
          // bottom: '0%',
          left: 'center',
          itemGap: 150,
          align:'left',
          textStyle: {
            fontSize: 13,//图例文字字体大小
            color: '#5BB2F1'//图例文字颜色
          },
        }
        ],
        color: ['#01dadc', '#23cea7', '#5096e0'],
        series: [
          {
            name: '单位用户',
            type: 'pie',
            startAngle: 0,
            radius: ['60%', '67%'],
            center: ['50%', '50%'],
            legendHoverLink: false,
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 300,
              itemStyle: {
                normal: {
                  color: "rgba(35,206,167,0)"
                }
              }
            },
              {
                value: 210,
                itemStyle: {
                  normal: {
                    color: "rgba(35,206,167,1)"
                  }
                }
              },
              {
                value: 90,
                itemStyle: {
                  normal: {
                    color: "rgba(35,206,167,0.1)"
                  }
                }
              },
            ]
          },
          {
            name: '居民用户',
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ["80%", "87%"],
            center: ['50%', '50%'],
            label: {
              normal: {
                show: false,
                position: 'center'
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold'
                }
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [{
              value: 300,
              itemStyle: {
                normal: {
                  color: "rgba(1,218,220,0)"
                }
              }
            },
              {
                value: 240,
                itemStyle: {
                  normal: {
                    color: "rgba(1,218,220,1)"
                  }
                }
              },
              {
                value: 60,
                itemStyle: {
                  normal: {
                    color: "rgba(1,218,220,0.1)"
                  }
                }
              },
            ]
          }
        ]
      };
      myChart.setOption(option)
    },
    pznd(){
      var myChart = echarts.init(document.getElementById('pznd'));
      var option = {
        title: {

        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          top:'10%',
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          show:false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          splitLine: {show: false},   //横向的线
          axisTick: {show: false},    //y轴的端点
          axisLine: {show: false},
          data: ['浓度检测报警装置', '联动切断装置',],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#5BB2F1',
              fontSize:'13px'//y轴字体颜色
            },
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: '#00b5eb',
              }
            },
            data: [0,0]
          },
        ]
      };
      myChart.setOption(option)
    },
    handler({ map }){
      map.setMapStyleV2({
        styleId: "1d571b1d27a9ad0f21498286133498d1",
      });
    },
    guan(e){
      console.log("guan",e.ctwingDeviceId)
      this.$http({
        method:"post",
        url:"/appApi/device/equipmentSwitch",
        data:{
          deviceId:e.ctwingDeviceId,
          typeOfCommandIssued:"06",
        }
      }).then(res=>{
       this.$message.success("发送成功")
      })
    },
    getshebei(){
      this.$http({
        method:"get",
        url:"/device/countingTheNumberOfDeviceStatuses"
      }).then(res=>{
        this.shebei=res.data.data
      })
    },
    geterror(){
      this.$http({
        method:"get",
        url:"/device/equipmentAlarmInformation",
        params:{
          deviceStatus:2
        }
      }).then(res=>{
        this.error=res.data.rows
      })
    },
    getLOCATION(){
      this.$http({
        method:"get",
        url:"/device/deviceLocationInformation"
      }).then(res=>{
        console.log(res,"12212")
        this.shebeiinfo=res.data.rows
        var show=false
        this.shebeiinfo.forEach(item=>{
          if(item.deviceStatus=="2"){
            show=true
          }else{
            show=false
          }
          this.points.push({lng:item.longitude,lat:item.latitude,
            deviceStatus:item.deviceStatus,
            userName:item.userName,
            userSPhoneNumber:item.userSPhoneNumber,
            address:item.address,
            gasMeterNumber:item.gasMeterNumber,
            deviceId:item.deviceId,
            ctwingDeviceId:item.ctwingDeviceId,
            region:item.region})
        })
      })
    },
    infoWindowOpen (e) {
      this.infoWindow.show = e.show
      this.infoWindow.lng=e.lng
      this.infoWindow.lat=e.lat
      this.infoWindow.userName=e.userName
      this.infoWindow.gasMeterNumber=e.gasMeterNumber
      this.infoWindow.address=e.address
      this.infoWindow.userSPhoneNumber=e.userSPhoneNumber
      this.infoWindow.deviceStatus=e.deviceStatus
      this.infoWindow.show=true
    },
    infoWindowClose(info){
      this.infoWindow.show=false
   },
    initWebSocket(){
      if (typeof WebSocket === "undefined") {return alert("您的浏览器不支持WebSocket")}
      else{
      }
      // websocket地址 其中 "ws" 对应的传输协议  "ws" -->http  "wss" -->https
      // let socketApi = "192.168.110.63:8888/websocket/";
      // let userId =  "zhushilong"; //和后端协定的 我这项目中是根据用户id进行区分
      // const wsuri  = 'ws://' +  socketApi  + userId;
      // 初始化socket方法
      this.websock = new WebSocket("ws://test.keyifazhan.com:7777/websocket/zhushilong");
      // 连接成功的处理
      this.websock.onopen = this.websocketonopen;
      // 接收数据之后的处理
      this.websock.onmessage = this.websocketonmessage;
      // 报错的处理
      this.websock.onerror = this.websocketonerror;
      // 关闭连接的处理
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen(){
      console.log('scoket已连接');
      const that= this;
      this.timer = setInterval(() => {
        try {
          // 向服务端标明本机心跳
          const jsonHeart = {
            status: 'heart',
            msg: '心跳',
            fromUserId: "zhushilong",
            toUserId: "xiaozhengwen",
            message: "消息内容145614521test"
          }
          if(that.websock.readyState !== 1){
            that.initWebSocket()
          }
        }catch (err) {
          that.connection()
        }
      },6000) //定时发送
    },
    websocketonmessage: async function (e) {
      var data=e.data
      if(data.includes("imei")==true){
        this.errordata=JSON.parse(data)
        var i=""
        let that=this
        this.points.forEach((item,index)=>{
          if(item.deviceId==that.errordata.imei){
            i=index
            return false;
          }
        })
        that.$set(that.points, i,{
          address:that.points[i].address,
          ctwingDeviceId:that.points[i].ctwingDeviceId,
          deviceId:that.points[i].deviceId,
          deviceStatus:that.errordata.deviceStatus,
          gasMeterNumber:that.points[i].gasMeterNumber,
          lng:that.points[i].lng,
          lat:that.points[i].lat,
          region:that.points[i].region,
          userName:that.points[i].userName,
          userSPhoneNumber:that.points[i].userSPhoneNumber,
          show:true
        })
        this.center.lng=that.errordata.lng
        this.center.lat=that.errordata.lat
        this.getshebei();
        this.geterror();
      }else{

      }
    },
  },mounted() {
    this.getshebei();
    this.geterror();
    this.getLOCATION();
    this.initWebSocket();
    this.qyzs();
    this.rqcz();
    this.spss();
    this.rqyh();
    this.ndjc();
    this.pzqtj();
    this.pznd();
  },
    beforeDestroy() {

  }
}
</script>

<style scoped>
.container_warpper{
  width:100%;
  height:100%;
  background-image: url("../assets/Group 48097361.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.taitou{
  width:100%;
  height:80px;
  background-image: url("../assets/taitou.png");
  background-repeat: no-repeat;
  background-size:cover;
  display: flex;
  justify-content: space-around;
  color:white;
  letter-spacing: 10px;
}
.taitou_title{
  flex:1;
  margin-top: 15px;
  font-family:优设标题黑;
  font-size: 30px;
  background: linear-gradient(top, #FFFFFF 39%, #31BEFF 79%);
  -webkit-background-clip:text;
  color:#FFFFFF;
}
.btn1{
  flex:1;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-family:"优设标题黑";
  font-size: 25px;
}
.btn_item{
  width:200px;
  height:40px;
  margin-left: 10px;
  background-image: url("../assets/zuo-weixuan@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn2{
  flex:1;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  font-family:"优设标题黑";
  font-size: 29px;
}
.btn_item2{
  width:200px;
  height:40px;
  margin-left: 10px;
  background-image: url("../assets/you-weixuan@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container{
  width:99%;
  height:980px;
  margin:auto;
  display:flex;
  justify-content: space-between;
}
.left{
  width:750px;
  display: flex;
  justify-content: space-between;
}
.left01{
  flex:1;
  height:980px;
}
.rqqye{
  width:100%;
  height:440px;
}
#qyzs{
  width:100%;
  height:320px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.cyry{
  width:100%;
  height:540px;
}
#cyry{
  width:100%;
  height:420px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
table{
  width:100%;
  color:#5BB2F1;
}
tr{
  width:100%;
  display: flex;
  align-items: center;
}
th{
  color:#E0F9FF;
}
th,td{
  width:100%;
  height:70px;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left02{
  flex:1;
  height:980px;
  padding-left: 10px;
  box-sizing: border-box;
}
.rqcz{
  width:100%;
  height:500px;
}
#rqcz{
  width:100%;
  height:380px;
  background-image: url("../assets/beijing-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.czjf{
  width:100%;
  height:480px;
}
#czjf{
  width:100%;
  height:400px;
  background-image: url("../assets/beijing-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.czjf_top_warpper{
  width:100%;
  height:410px;
  color:#5BB2F1;
  background-image: url("../assets/beijing-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.czjf_top{
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.czjf_top_item{
  width:100px;
  height:80px;
}
.bm-view{
  flex:2;
  width:100%;
  height:100%;
}
.right{
  width:750px;
  display: flex;
}
.right01{
  width:750px;
  height:980px;
}
.spss{
  width:100%;
  height:500px;
}
.spss_wrapper{
  width:100%;
  height:380px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#spss{
  flex:1;
  height:150px;
}
.spss_right{
  flex:1;
  height:150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color:#5BB2F1;
}
.spss_right div{
  display: flex;
  justify-content: space-around;
}
.middle{
  width:95%;
  height:100px;
  margin: auto;
  margin-top: 5px;
  background-image: url("../assets/chuangkou.png");
  background-repeat: no-repeat;
  background-size: cover;
  color:#5BB2F1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.gwjf{
  width:100%;
  height:480px;
}
.gwjf2{
  width:100%;
  height:430px;
  background-image: url("../assets/beijing-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  color:#5BB2F1;
}
.right02{
  width:750px;
  padding-left: 10px;
  box-sizing: border-box;
}

.rqyh{
  width:100%;
  height:450px;
}
.rq_container{
  width:100%;
  height:350px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#rqyh{
  width:100%;
  height:150px;
}
.gdjfsb{
  width:100%;
  height:260px;
  background-image: url("../assets/chuangkou-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding:20px 10px 0px 10px;
  box-sizing: border-box;
}
.gdjf_text{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:#9CEDFF;
}
#ndjc{
  width:100%;
  height:160px;
}
.pzq{
  width:100%;
  height:520px;
  margin-top: 10px;

}
.pz_container{
  width:100%;
  height:460px;
  margin-top:10px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
#pzqtj{
  width:100%;
  height:200px;
}
#pznd{
  width:100%;
  height:150px;
}
.title{
  width:100%;
  height:50px;
  color:#EDF8FF;
  font-family: 优设标题黑;
  background-image: url("../assets/yiji biaoti beijing.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  font-size: 23px;
  align-items: center;
  padding:0px 15px 0px 15px;
  box-sizing: border-box;
}
.sub_title{
  width:100%;
  height:60px;
  background-image: url("../assets/yiji biaoti beijing.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0px 15px 0px 15px;
  box-sizing: border-box;
  color:#9CEDFF;
  font-family: 微软雅黑;
}
.sub_title2{
  width:100%;
  height:40px;
  background-image: url("../assets/yiji biaoti beijing.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:0px 15px 0px 15px;
  box-sizing: border-box;
  color:#9CEDFF;
  font-family: 微软雅黑;
  border-bottom: 1px solid rgba(182,193,206,0.39);;
}
.number{
  font-size:25px;
  color:#D9F8FF;
  font-family:D-DIN-DIN-Bold;
}
.sucess_window{
  width:350px;
  height:450px;
  margin-top:5px;
  background-image: url("../assets/beijing-2.png");
  background-size: cover;
  padding:0px 20px 0px 20px;
  border: 1px solid #80A1E4;
}
.bm-view .BMap_pop>div:nth-child(1){
 display: none;
}
.bm-view .BMap_pop .BMap_top{
  display: none;
  background-color: transparent;
}
.bm-view .BMap_pop>div:nth-child(3){
  display: none;
}
.bm-view .BMap_pop>img{
  display: none;
}
.bm-view .BMap_pop .BMap_center{
  background-color:transparent;
  border: none;
}
.bm-view .BMap_pop .BMap_bottom{
  display: none;
}
.bm-view .BMap_pop>div:nth-child(5){
  display: none;
}
.bm-view .BMap_pop>div:nth-child(7){
  display: none;
}
.bm-view .BMap_pop div:nth-child(8) {
  display: none;
  background-color: red;
}

.err_window{
  width:350px;
  height:300px;
  margin-top:5px;
  background: linear-gradient(170deg, #9D4230 0%, #4F080A 100%);
  box-shadow: 0px 7px 71px 0px rgba(146,0,0,0.38);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #E48080;
  padding:0px 20px 0px 20px;
}
.bm-view .infowindow .BMap_pop>div:nth-child(1){
  display: none;
}
.bm-view .infowindow .BMap_pop .BMap_top{
  display: none;
  background-color: transparent;
}
.bm-view .infowindow .BMap_pop>div:nth-child(3){
  display: none;
}
.bm-view .infowindow .BMap_pop>img{
  display: none;
}
.bm-view .infowindow .BMap_pop .BMap_center{
  background-color:transparent;
  border: none;
}
.bm-view .infowindow .BMap_pop .BMap_bottom{
  display: none;
}
.bm-view .infowindow .BMap_pop>div:nth-child(5){
  display: none;
}
.bm-view .infowindow .BMap_pop>div:nth-child(7){
  display: none;
}
.bm-view .infowindow .BMap_pop div:nth-child(8) {
  display: none;
  background-color: red;
}
.title2{
  color:white;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height:40px;
  /*margin:20px 0px 20px 0px;*/
}
.info_title{
  height:40px;
  background-image: url("../assets/yiji biaoti beijing.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
  color:#EDF8FF;
  font-size: 20px;
  font-family: 优设标题黑;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
.bottm_menu{
  width:700px;
  height:50px;
  background-image: url("../assets/di@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  transform: translate(-50%,-50%);
  top:96%;
  left:50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bott2{
  width:480px;
  height:50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
}
</style>
