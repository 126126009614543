/****
 *
 * - renderDom（可选）：渲染的dom，默认是 "#app"，必须使用id选择器
 * - designWidth（可选）：设计稿的宽度，默认是 1920
 * - designHeight（可选）：设计稿的高度，默认是 929 ，如果项目以全屏展示，则可以设置为1080
 * - resize（可选）：是否监听resize事件，默认是 true
 *
 * ***/


const autofit = {
  init(options = {}, isShowInitTip = true) {
    if (isShowInitTip) {
      console.log(`%c` + `autofit.js` + ` is running`, `color: #fff; background: #f40; padding: 2px 4px; border-radius: 4px;`);
    }
    let designWidth = options.designWidth || 2550;
    let designHeight = options.designHeight || 1000;
    let renderDom = options.renderDom || "#app";
    let resize = options.resize || true;
    let ignore = options.ignore || [];
    let dom = document.querySelector(renderDom)
    const style = document.createElement('style');
    style.lang = 'text/css';
    style.id = 'autofit-style';
    style.innerHTML = `
      body {
        // overflow: hidden;
      }
    `;
    dom.appendChild(style);
    dom.style.height = `${designHeight}px`;
    dom.style.width = `${designWidth}px`;
    dom.style.transformOrigin = `0 0`;
    keepFit(designWidth, designHeight, dom, ignore);
    resize && (window.onresize = () => {
      keepFit(designWidth, designHeight, dom, ignore);
    })
  }
}
function keepFit(designWidth, designHeight, dom, ignore) {
  let clientHeight = document.documentElement.clientHeight;
  let clientWidth = document.documentElement.clientWidth;
  let scale = (clientWidth / clientHeight < designWidth / designHeight) ? (clientWidth / designWidth) : (clientHeight / designHeight)
  dom.style.height = `${clientHeight / scale}px`;
  dom.style.width = `${clientWidth / scale}px`;
  dom.style.transform = `scale(${scale})`;
  for (let item of ignore) {
    let realScale = (item.scale ? item.scale : 1 / scale)
    let realFontSize = realScale != scale ? item.fontSize : 'autofit'
    let realWidth = realScale != scale ? item.width : 'autofit'
    let realHeight = realScale != scale ? item.height : 'autofit'
    document.querySelector('#autofit-style').innerHTML += `${item.dom}{
      transform: scale(${realScale})!important;
      transform-origin: 50% 0;
      width: ${realWidth}px!important;
      height: ${realHeight}px!important;
    }`;
    document.querySelector('#autofit-style').innerHTML += `${item.dom} div ,${item.dom} span,${item.dom} a,${item.dom} *{
    font-size: ${realFontSize}px;
    }`;
  }
}
export default autofit;
