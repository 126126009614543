import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/text/text.css"
// import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//写在main.js中
Vue.prototype.$bus = new Vue()

//励志本地 
// axios.defaults.baseURL="http://192.168.110.25:7777/"

//gaocheng
// axios.defaults.baseURL="http://36.140.249.44:7777" //藁城线上

// axios.defaults.baseURL="http://192.168.110.228:7778/"
//cangxain
axios.defaults.baseURL="https://cangxian.keyifazhan.com:8889/" //线
// axios.defaults.baseURL="http://106.63.9.24:8890/" //测试

// axios.defaults.baseURL="http://192.168.110.63:8889/"
//xianxian
// axios.defaults.baseURL="http://xianxian.keyifazhan.com:8888"

//changli
// axios.defaults.baseURL="http://106.8.25.78:7777/" // 昌黎线上
import VScaleScreen from 'v-scale-screen'
Vue.use(VScaleScreen)
Vue.prototype.$http=axios
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'DSiKhppxMOiosBzcGU4am2gGe3gGYrbA'
// })
// import VueBMap from 'vue-bmap-gl';
// import 'vue-bmap-gl/dist/style.css'
// import VueMapvgl  from 'vue-mapvgl';
// Vue.use(VueBMap);
// Vue.use(VueMapvgl);
// VueBMap.initBMapApiLoader({
//   ak: 'DSiKhppxMOiosBzcGU4am2gGe3gGYrbA',
//   v: '2.0'
// });
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
