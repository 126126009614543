<template>
  <!--  <v-scale-screen width="1920" height="1080">-->
  <div id="app">
    <router-view />
  </div>
  <!--  </v-scale-screen>-->
</template>
<script>
import VScaleScreen from "v-scale-screen";
import top from "../src/components/top";
import autofit from "../autofit";
import Adaptor from "adaptorjs";
export default {
  name: "App",
  components: { top, VScaleScreen },
  data() {
    return {
      adaptor: null,
    };
  },

  mounted() {
    this.adaptor = new Adaptor({
      designHeight: 1080,
      designWidth: 1920,
      querySelector: "#app",
      extraSelectors: [""],
    });
    // autofit.init({
    //   // designHeight:1080,
    //   // designWidth:2470,
    //   designWidth: 1920,
    //   designHeight: 1080,
    //   renderDom: "#app",
    //   resize: true,
    // });
  },
  destroyed() {
    this.adaptor.destroy();
  },
  methods: {},
};
</script>
<style>
#app,
html,
body {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*background: linear-gradient(rgba(6,36,94,0) 0%, rgba(6,36,94,0.8) 33%, #06245E 100%);*/
  /*background-repeat: no-repeat;*/
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

/** 修改select下拉框的样式 */
.el-popper[x-placement^="bottom"] {
  background-color: #00317a;
  border-color: #00d1ff;
}

.el-select-dropdown__item {
  color: #fff;
}

.el-select-dropdown__item:hover,
.el-select-dropdown__item.hover {
  color: #fff;
  background-color: #00317a;
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #00317a !important;
}

.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #00d1ff;
}

.el-select-dropdown__item.selected {
  color: #78ddec;
  background-color: #00317a;
}
.select-style .el-select-dropdown {
  position: absolute !important;
}
.select-style .el-icon-arrow-up:before {
  content: "";
  width: 10px;
  height: 10px;
  /* background: url("/images/demonstration/room-create-img/select-click.png") */
  /* center no-repeat; */
  background-size: 100% 100%;
  transform: rotate(0deg);
}
.select-style .el-select .el-input__inner {
  border: none;
  /* background: url("/images/demonstration/common-img/input-notCheck.png") */
  /* no-repeat; */
  background-size: 100% 100%;
  color: #e0ecf5;
}
.el-select-dropdown {
  background-color: #00317a !important;
  border: 1px solid #3d6aaf !important;
  color: #ffffff !important;
}
.el-select-dropdown__item {
  color: #ffffff !important;
}
.el-select-dropdown__item.hover {
  background-color: #0774ad !important;
  color: #ffffff;
}
</style>
